/* You can add global styles to this file, and also import other style files */
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
  * {
    font-family: PingFangTC-Medium, sans-serif;
  }
.frame{
    width: 100%;
    height: 300px;
    border: 1px solid blue;
}
.bgcolor{
    background-color:#f9f5f0 ;
}
.color01{
    color: #b18247;
}
.color02{
    color:#767676;
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
.card-columns {
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
  @include media-breakpoint-only(xs) {
    column-count: 2;
  }
}

// owl dot
.owl-theme .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  margin: 5px 7px;
  background: #A3A09D;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all .2s ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #b18247;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all .2s ease;
  border-radius: 30px;
}
.owl-dot{
  vertical-align: middle;
}
#carouselContainer .owl-dots{
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
}